<template>
  <div :class="{ home: true, openMenus: !isCollapse, closeMenus: isCollapse }">
    <div class="menus">
      <div
        class="text-center font-bold text-white pt-20 pb-10 flex items-center justify-center"
      >
        <img :title="$sysTitle" src="@/assets/favicon.jpg" height="30px" /><span v-if="!isCollapse">{{ $sysTitle }}</span>
      </div>
      <el-menu
        :collapse="isCollapse"
        default-active="home"
        background-color="#304156"
        text-color="#fff"
        active-text-color="#409eff"
        router
      >
        <el-menu-item index="/home">
          <i class="el-icon-s-home"></i>
          <span slot="title">首页</span>
        </el-menu-item>
        <el-menu-item index="/home/car">
          <i class="el-icon-truck"></i>
          <span slot="title">车辆管理</span>
        </el-menu-item>
        <el-menu-item index="/home/brand">
          <i class="el-icon-postcard"></i>
          <span slot="title">品牌管理</span>
        </el-menu-item>
        <el-menu-item index="/home/carousel">
          <i class="el-icon-picture-outline"></i>
          <span slot="title">首页轮播图管理</span>
        </el-menu-item>
        <el-menu-item index="/home/category">
          <i class="el-icon-edit-outline"></i>
          <span slot="title">车辆类型管理</span>
        </el-menu-item>
        <el-menu-item index="/home/convertible">
          <i class="el-icon-reading"></i>
          <span slot="title">敞篷管理</span>
        </el-menu-item>
        <el-menu-item index="/home/seat">
          <i class="el-icon-data-board"></i>
          <span slot="title">座位管理</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="content">
      <div class="navbar">
        <div class="navbar-btn" @click="isCollapse = !isCollapse">
          <i class="el-icon-s-fold" v-if="!isCollapse"></i>
          <i class="el-icon-s-unfold" v-else></i>
        </div>
        <div class="navbar-right">
          <el-dropdown trigger="click">
            <span>
              <!-- <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar><i class="el-icon-caret-bottom el-icon--right"></i> -->
              管理员<i class="el-icon-caret-bottom el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item icon="el-icon-s-custom">个人信息</el-dropdown-item> -->
              <el-dropdown-item @click.native="logout" icon="el-icon-caret-left"
                >退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <!-- 内容部分 -->
      <div class="section">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { removeToken } from "@/utils/auth";
import { mapMutations } from "vuex";
export default {
  name: "Home",

  data() {
    return {
      isCollapse: false,
    };
  },
  methods: {
    ...mapMutations(["setToken", "setRole"]),
    logout() {
      this.$confirm("确认退出登录吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.setToken("");
          removeToken();
          setTimeout(() => {
            this.$router.replace("/login");
          }, 1000);
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.openMenus {
  .menus {
    height: 100vh;
    width: 200px;
    overflow-y: auto;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #304156;
    transition: all 0.5s;

    .el-menu {
      border-right: none;
    }
  }

  .content {
    // background: darkcyan;
    margin-left: 200px;
    width: calc(100% - 200px);
    height: 100vh;
    transition: all 0.5s;
  }
}

.closeMenus {
  .menus {
    height: 100vh;
    width: 64px;
    overflow-y: auto;
    position: fixed;
    left: 0;
    top: 0;
    transition: all 0.5s;
    background-color: #304156;

    .el-menu {
      border-right: none;
    }
  }

  .content {
    // background: darkcyan;
    margin-left: 64px;
    width: calc(100% - 64px);
    height: 100vh;
    transition: all 0.5s;
  }
}

.home {
  .navbar {
    height: 60px;
    // background-color: darkcyan;
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: space-between;
    border-bottom: 1px solid #eee;

    .navbar-btn {
      // width: 60px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      // background-color: darkblue;
    }

    .navbar-right {
      min-width: 80px;
      height: 40px;
      display: flex;
      align-items: center
      // background-color: darkmagenta;
    }
  }

  .content {
    .section {
      padding: 16px;
    }
  }
}
</style>
