import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import {getToken} from '@/utils/auth'
Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: "/home"
	},
  {
		path: '/login',
    name: 'Login',
		component: () => import(/* webpackChunkName: "welcome" */ '@/views/Login.vue')
	},
  {
    path: '/home',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '',
        name: 'Welcome',
        component: () => import(/* webpackChunkName: "welcome" */ '@/views/Welcome.vue')
      },
      {
        path: 'brand',
        name: 'brand',
        component: () => import(/* webpackChunkName: "Brand" */ '@/views/Brand.vue')
      },
      {
        path: 'car',
        name: 'car',
        component: () => import(/* webpackChunkName: "Car" */ '@/views/Car.vue')
      },
      {
        path: 'carousel',
        name: 'carousel',
        component: () => import(/* webpackChunkName: "Carousel" */ '@/views/Carousel.vue')
      },
      {
        path: 'category',
        name: 'category',
        component: () => import(/* webpackChunkName: "Category" */ '@/views/Category.vue')
      },
      {
        path: 'convertible',
        name: 'convertible',
        component: () => import(/* webpackChunkName: "Convertible" */ '@/views/Convertible.vue')
      },
      {
        path: 'seat',
        name: 'seat',
        component: () => import(/* webpackChunkName: "Seat" */ '@/views/Seat.vue')
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

//无需登录的页面
const whiteList = [
  "/login"
]

//路由拦截
router.beforeEach((to, from, next) => {
	//console.log(to);
	//console.log(from);
	/* 路由发生变化修改页面title */
	// if (to.meta.title) {
	//   document.title = to.meta.title
	// };
	const isLogin = getToken()
	if (isLogin) { //登录之后直接放行
		next()
	} else {
		//无需登录的页面放行
		if (whiteList.includes(to.fullPath) || whiteList.includes(to.path)) {
			next();
		} else {
			next({
				path: '/login'
			})
		};
	};
})

export default router
